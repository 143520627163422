import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import {
  alpha,
  Box,
  Card,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import CustomTable, {
  ColumnsSettingsData,
  DataColumnConfigProps,
  StickyColumnConfigProps,
} from "../../components/MaterialTable/Table";
import RowActions from "./RowActions";
import { rowsPerPageOptionsStandard } from "../../utils/constants/gridsOptions";
import UserContext, {
  isUserAdminOrSemiAdminWith,
  isUserInRoles,
  ServerOrgRoles,
  UserRoleGroups,
} from "../../services/UserContext";
import {
  businessForecastRecordType,
  ForecastExtendedFiltersProps,
  ForecastFiltersProps,
  ForecastPagination,
  ForecastRecord,
  ForecastSortOption,
} from "../../utils/types/Forecast";
import { format } from "date-fns";
import { customColors } from "../../theme/MUITheme";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import SimpleModal from "../../components/Modals/SimpleModal";
import { getColumnsConfig, getStickyColumnsConfig } from "./columns.config";
import { defaultEmptyLine } from "../../components/Widgets/RichTextEditor";
import { RequestStatus } from "../../utils/Helpers/fetchStatus";
import toast from "react-hot-toast";
import { generateExcel } from "../../services/exporter";
import {
  getExcelData,
  getExportableDataTable,
  getSortableColumnPropertyName,
  getStatusPill,
  getTableHeader,
} from "./forecastUtils";
import { FiltersIcon } from "../../components/Icons/FiltersIcon";
import Button from "@mui/material/Button";
import ForecastFilters from "./ForecastFilters";
import EditIcon from "@mui/icons-material/Edit";
import { debounce, uniq } from "lodash";
import { useForm } from "react-hook-form";
import { RootState, useDispatch, useSelector } from "../../store";
import {
  getForecastFiltersDropdownOptions,
  getForecasts,
  getTableSettings,
  patchTableSettings,
  resetGetForecasts,
  resetSaveAdminDataStatus,
  resetShareOpportunitiesStatus,
  resetTableSettings,
  saveAdminData,
  shareOpportunities,
  updateAfterAdminEdit,
} from "../../slices/forecast";
import Input from "../../components/Widgets/Inputs/Input";
import {
  getPipelines,
  getPipelinesForRecord,
  resetAddOrRemoveFromPipelinesStatus,
  resetPipelinesForRecord,
  resetPipelinesState,
  setPipelinesForRecordFor,
  updatePipelines,
} from "../../slices/pipelines";
import { getOrganizationUsers } from "../../slices/organization";
import ReplyIcon from "@mui/icons-material/Reply";
import AddMultipleOpportunitiesToPipelinesModal from "./AddMultipleOpportunitiesToPipelinesModal";
import { buildQuery } from "../../utils/Helpers/queryBuilder";
import { getISODateWithAdjustedTimezone } from "../../utils/conversion/date-converters";
import ProjectsTable from "../Projects/ProjectsTable";
import { handleInvalidDatesToast } from "../../utils/Helpers/invalidDatesErrorToastHandler";
import ShareModal from "../../components/Widgets/USAIDBidSearchComponents/ShareModal";
import InfoIcon from "@mui/icons-material/Info";
import { constants } from "../../utils/constants/general";

const icons = {
  addToPipeline: require("../../assets/icons/AddToPipeline.png")?.default,
};

const initialPagination: ForecastPagination = {
  page: 0,
  rowsPerPage: rowsPerPageOptionsStandard[0],
};

const initialSortOption: ForecastSortOption = {
  sortOrder: 0,
  sortField: "",
};

const initialFilters: ForecastFiltersProps = {
  keyword: "",
  liveOnly: false,
  newOnly: false,
  OnlyUntrackedInPipeline: false,
};

const FILTERS_FORM_ID = "forecast-filters-drawer";
const ADMIN_EDIT_FORM_ID = "admin-edit-form";

export const FORECAST_COLUMNS_SETTINGS_KEY = "forecastColumnsSettings";

const filterFormDefaultValues: ForecastExtendedFiltersProps = {
  Countries: [],
  DateOfChangeBefore: null,
  DateOfChangeAfter: null,
  OpportunityTextKeyword: "",
  OpportunityDescriptionKeyword: "",
  SuggestedPrimesKeyword: "",
  QuarterlyForecastNotesKeyword: "",
  Sectors: [],
  // totalEstimatedCost: "",
  AwardTypes: [],
  GeographicalCodes: [],
  AnticipatedReleaseDateBefore: null,
  AnticipatedReleaseDateAfter: null,
  AnticipatedAwardDateBefore: null,
  AnticipatedAwardDateAfter: null,
};

const adminEditFormDefaultValues = {
  subPrimes: null,
  quarterlyCallNotes: null,
  externalLinkHtml: null,
  externalLinkText: null,
};

const ForecastScreen: FC = (): JSX.Element => {
  const theme = useTheme();
  const context = useContext(UserContext);
  const dispatch = useDispatch();

  const history = useHistory();

  const {
    items: forecastData,
    total,
    totalLiveRecords,
    fetchStatus,
    lastUpdatedUtc,
    saveAdminData: { postFetchStatus: saveAdminEditsFetchStatus },
    shareOpportunities: { postFetchStatus: shareOpportunitiesFetchStatus },
    dropdownOptions: {
      fetchStatus: dropdownOptionsFetchStatus,
      contriesOffices,
      sectors,
      awardTypes,
      geographicalCodes,
    },
    tableSettings: { columnsOrder, fetchStatus: tableSettingsFetchStatus },
  } = useSelector((state: RootState) => state.forecast);

  const {
    rawItems: rawPipelines,
    items: pipelines,
    fetchStatus: pipelinesFetchStatus,
    addOrRemoveFromPipelines: {
      postFetchStatus: addOrRemoveFromPipelinesFetchStatus,
    },
  } = useSelector((state: RootState) => state.pipelines);

  const {
    fetchStatus: pipelinesForRecordFetchStatus,
    itemsFor: pipelinesForRecordFor,
  } = useSelector((state: RootState) => state.pipelines.pipelinesForRecord);

  const isFree = !isUserInRoles(context, UserRoleGroups.plusOrHigher);
  const isPlus = isUserInRoles(context, UserRoleGroups.plusOrHigher);
  const isPro = isUserInRoles(context, UserRoleGroups.proOrHigher);
  const canShare = isPlus || isPro;
  const canEditPrimes = isUserAdminOrSemiAdminWith(
    context,
    ServerOrgRoles.EditPrimesAndQuarterlyCallInfo,
  );

  const getFilterValuesFromURL = (): {
    pagination: ForecastPagination;
    sortOption: ForecastSortOption;
    filters: ForecastFiltersProps;
    extendedFilters: ForecastExtendedFiltersProps;
  } => {
    const searchParams = new URLSearchParams(history.location.search);
    let params: ForecastPagination &
      ForecastSortOption &
      ForecastFiltersProps &
      ForecastExtendedFiltersProps = {
      ...initialPagination,
      ...initialSortOption,
      ...initialFilters,
      ...filterFormDefaultValues,
    };
    const booleanKeys: Array<string> = [
      "liveOnly",
      "newOnly",
      "OnlyUntrackedInPipeline",
    ];
    const numberKeys: Array<string> = ["" + "page", "rowsPerPage", "sortOrder"];
    const arrayKeys: Array<string> = [
      "Countries",
      "Sectors",
      "AwardTypes",
      "GeographicalCodes",
    ];
    searchParams.forEach((value, key) => {
      params[key] = booleanKeys.includes(key)
        ? value === "true"
        : numberKeys.includes(key)
        ? +value
        : arrayKeys.includes(key)
        ? [...params?.[key], { value, label: value }]
        : value;
    });

    const {
      page,
      rowsPerPage,
      keyword,
      liveOnly,
      newOnly,
      OnlyUntrackedInPipeline,
      sortOrder,
      sortField,
      ...extendedFilters
    } = params;

    return {
      pagination: isFree ? { page: 0, rowsPerPage: 20 } : { page, rowsPerPage },
      sortOption: isFree ? initialSortOption : { sortField, sortOrder },
      filters: {
        keyword: isFree ? "" : keyword,
        liveOnly,
        newOnly,
        OnlyUntrackedInPipeline,
      },
      extendedFilters: isFree
        ? filterFormDefaultValues
        : { ...filterFormDefaultValues, ...extendedFilters },
    };
  };

  const [columns, setColumns] = useState<Array<any>>([]);

  // used to catch case when user has filters selected and they are included in url and user clicks on Forecast nav link in sidebar menu
  const [preventFiltersEffectCall, setPreventFiltersEffectCall] =
    useState<boolean>(false);

  const [pagination, setPagination] = useState<ForecastPagination>(
    getFilterValuesFromURL().pagination,
  );
  const [sortOption, setSortOption] = useState<ForecastSortOption>(
    getFilterValuesFromURL().sortOption,
  );
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<ForecastFiltersProps>(
    getFilterValuesFromURL().filters,
  );
  const [extendedFilters, setExtendedFilters] =
    useState<ForecastExtendedFiltersProps>(filterFormDefaultValues);
  const [keywordSearchInputValue, setKeywordSearchInputValue] =
    useState<string>("");

  const [rfpRfiModalOpen, setRfpRfiModalOpen] = useState<boolean>(false);
  const [rfpRfiModalOpenFor, setRfpRfiModalOpenFor] =
    useState<ForecastRecord | null>(null);

  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [editModalOpenFor, setEditModalOpenFor] =
    useState<ForecastRecord | null>(null);

  const [upgradePlanModalOpen, setUpgradePlanModalOpen] =
    useState<boolean>(false);

  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const [shareModalOpenFor, setShareModalOpenFor] = useState<
    ForecastRecord | Array<string> | null
  >(null);

  const [selectedRecords, setSelectedRecords] = useState<Array<string>>([]);

  const [bulkAddToPipelinesModalOpen, setBulkAddToPipelinesModalOpen] =
    useState<boolean>(false);
  const [bulkAddToPipelinesModalOpenFor, setBulkAddToPipelinesModalOpenFor] =
    useState<Array<string> | null>(null);

  const [addToPipelinesOpen, setAddToPipelinesOpen] = useState<
    string | number | null
  >(null);

  const {
    control: adminEditControl,
    handleSubmit: adminEditHandleSubmit,
    getValues: adminEditGetValues,
    setValue: adminEditSetValue,
    reset: adminEditReset,
  } = useForm<{
    subPrimes: string | null;
    quarterlyCallNotes: string | null;
    externalLinkHtml: string | null;
    externalLinkText: string | null;
  }>({
    defaultValues: adminEditFormDefaultValues,
  });

  const { control, handleSubmit, reset, getValues } =
    useForm<ForecastExtendedFiltersProps>({
      defaultValues: getFilterValuesFromURL().extendedFilters,
    });

  const stickyColumns = getStickyColumnsConfig(canShare, isFree);

  const handlePageChange = (
    e: React.MouseEvent<HTMLButtonElement>,
    page: number,
  ): void => {
    setPagination((prev) => ({
      ...prev,
      page,
    }));
  };

  const handleRowsPerPageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    setPagination((prev) => ({
      ...prev,
      page: 0,
      rowsPerPage: +e.target.value,
    }));
  };

  const handleSaveColumnsConfiguration = (data: {
    [key: string]: Array<ColumnsSettingsData>;
  }): void => {
    dispatch(
      patchTableSettings({
        context,
        key: FORECAST_COLUMNS_SETTINGS_KEY,
        configuration: data,
      }),
    );
  };

  const handleSetDefaultValuesForAdminEdits = (item: ForecastRecord): void => {
    typeof item?.primeOrIncumbent === "string" &&
      adminEditSetValue("subPrimes", item?.primeOrIncumbent);
    adminEditSetValue("quarterlyCallNotes", item?.quarterlyCallNotes);
    adminEditSetValue("externalLinkHtml", item?.externalLinkHtml);
    adminEditSetValue("externalLinkText", item?.externalLinkText);
  };

  const handleOpenEditModal = (item: ForecastRecord): void => {
    handleSetDefaultValuesForAdminEdits(item);
    setEditModalOpen(true);
    setEditModalOpenFor(item);
  };

  const handleCloseEditModal = (): void => {
    setEditModalOpen(false);
    setTimeout(() => {
      adminEditReset();
    }, 150);
  };

  const handleOpenShareModal = (item: ForecastRecord | Array<string>): void => {
    setShareModalOpen(true);
    setShareModalOpenFor(item);
  };

  const handleCloseShareModal = (): void => {
    setShareModalOpen(false);
  };

  const handleOpenBulkAddToPipelinesModal = (): void => {
    setBulkAddToPipelinesModalOpen(true);
    const ids = selectedRecords.map((record) => record.split("-")[1]);
    const uniqueIds = uniq(ids);
    setBulkAddToPipelinesModalOpenFor(uniqueIds);
  };

  const handleCloseBulkAddToPipelinesModal = (): void => {
    setBulkAddToPipelinesModalOpen(false);
  };

  const handleOpenRfpRfiModal = (item: ForecastRecord): void => {
    setRfpRfiModalOpenFor(item);
    setRfpRfiModalOpen(true);
  };

  const handleCloseRfpRfiModal = (): void => {
    setRfpRfiModalOpen(false);
    setTimeout(() => {
      setRfpRfiModalOpenFor(null);
    }, 150);
  };

  const handleOpenFilters = (): void => {
    setFiltersOpen(true);
  };

  const handleCloseFilters = (): void => {
    setFiltersOpen(false);
  };

  const handleCancelFilters = (): void => {
    handleCloseFilters();
    setTimeout(() => {
      reset({
        Countries: extendedFilters.Countries.map((country) => ({
          value: country as string,
          label: country as string,
        })),
        DateOfChangeBefore: extendedFilters.DateOfChangeBefore
          ? new Date(extendedFilters.DateOfChangeBefore)
          : null,
        DateOfChangeAfter: extendedFilters.DateOfChangeAfter
          ? new Date(extendedFilters.DateOfChangeAfter)
          : null,
        OpportunityTextKeyword: extendedFilters.OpportunityTextKeyword,
        OpportunityDescriptionKeyword:
          extendedFilters.OpportunityDescriptionKeyword,
        SuggestedPrimesKeyword: extendedFilters.SuggestedPrimesKeyword,
        QuarterlyForecastNotesKeyword:
          extendedFilters.QuarterlyForecastNotesKeyword,
        Sectors: extendedFilters.Sectors.map((sector) => ({
          value: sector as string,
          label: sector as string,
        })),
        // totalEstimatedCost: "",
        AwardTypes: extendedFilters.AwardTypes.map((type) => ({
          value: type as string,
          label: type as string,
        })),
        GeographicalCodes: extendedFilters.GeographicalCodes.map((code) => ({
          value: code as string,
          label: code as string,
        })),
        AnticipatedReleaseDateBefore:
          extendedFilters.AnticipatedReleaseDateBefore
            ? new Date(extendedFilters.AnticipatedReleaseDateBefore)
            : null,
        AnticipatedReleaseDateAfter: extendedFilters.AnticipatedReleaseDateAfter
          ? new Date(extendedFilters.AnticipatedReleaseDateAfter)
          : null,
        AnticipatedAwardDateBefore: extendedFilters.AnticipatedAwardDateBefore
          ? new Date(extendedFilters.AnticipatedAwardDateBefore)
          : null,
        AnticipatedAwardDateAfter: extendedFilters.AnticipatedAwardDateAfter
          ? new Date(extendedFilters.AnticipatedAwardDateAfter)
          : null,
      });
    }, 150);
  };

  const handleClearFilters = (): void => {
    reset(filterFormDefaultValues);
  };

  const onKeywordChange = (e) => {
    setKeywordSearchInputValue(e.target.value);
    debouncedKeywordChange(e.target.value);
  };

  const searchCallback = (value) => {
    setFilters((prev) => ({ ...prev, keyword: value }));
  };

  const debouncedKeywordChange = useCallback(debounce(searchCallback, 750), []);

  const onFiltersSubmit = (values: any): void => {
    handleInvalidDatesToast(values, () => {
      const data = {
        Countries: values.Countries.map((country) => country.value),
        DateOfChangeBefore: values.DateOfChangeBefore
          ? format(new Date(values.DateOfChangeBefore), "MM-dd-yyyy")
          : null,
        DateOfChangeAfter: values.DateOfChangeAfter
          ? format(new Date(values.DateOfChangeAfter), "MM-dd-yyyy")
          : null,
        OpportunityTextKeyword: values.OpportunityTextKeyword,
        OpportunityDescriptionKeyword: values.OpportunityDescriptionKeyword,
        SuggestedPrimesKeyword: values.SuggestedPrimesKeyword,
        QuarterlyForecastNotesKeyword: values.QuarterlyForecastNotesKeyword,
        Sectors: values.Sectors.map((sector) => sector.value),
        // totalEstimatedCost: "",
        AwardTypes: values.AwardTypes.map((type) => type.value),
        GeographicalCodes: values.GeographicalCodes.map((code) => code.value),
        AnticipatedReleaseDateBefore: values.AnticipatedReleaseDateBefore
          ? format(new Date(values.AnticipatedReleaseDateBefore), "MM-dd-yyyy")
          : null,
        AnticipatedReleaseDateAfter: values.AnticipatedReleaseDateAfter
          ? format(new Date(values.AnticipatedReleaseDateAfter), "MM-dd-yyyy")
          : null,
        AnticipatedAwardDateBefore: values.AnticipatedAwardDateBefore
          ? format(new Date(values.AnticipatedAwardDateBefore), "MM-dd-yyyy")
          : null,
        AnticipatedAwardDateAfter: values.AnticipatedAwardDateAfter
          ? format(new Date(values.AnticipatedAwardDateAfter), "MM-dd-yyyy")
          : null,
      };
      setExtendedFilters((prev) => ({
        ...prev,
        ...data,
      }));
      handleCloseFilters();
    });
  };

  const onShareSubmit = (values: any): void => {
    dispatch(
      shareOpportunities({
        context,
        ...values,
      }),
    );
  };

  const onAdminEditSubmit = (values: any): void => {
    if (!editModalOpenFor) return;
    const { rowId } = editModalOpenFor;
    dispatch(
      saveAdminData({
        context,
        loadedForecastId: rowId,
        subPrimes:
          values?.subPrimes === defaultEmptyLine ? null : values?.subPrimes,
        quarterlyCallNotes:
          values?.quarterlyCallNotes === defaultEmptyLine
            ? null
            : values?.quarterlyCallNotes,
        externalLinkHtml:
          values?.externalLinkHtml === defaultEmptyLine
            ? null
            : values?.externalLinkHtml,
        externalLinkText:
          values?.externalLinkText === "\n" ? null : values?.externalLinkText,
      }),
    );
  };

  const adjustPipelinesData = async (): Promise<void> => {
    // const chunkSize = 10;
    // const chunksAmount = Math.ceil(rawPipelines.length / chunkSize);
    // const allPipelines: any = [];
    //
    // for (let i = 0; i < chunksAmount; ++i) {
    //   const start = i * chunkSize;
    //   const records = await Promise.all(
    //     rawPipelines
    //       .slice(start, start + chunkSize)
    //       .map(async (x: PipelineDto) => {
    //         const sa = await axios.get(
    //           getFullUrl(`/api/pipeline/${x.id}/forecast`, {
    //             useDedicatedEnvironment: true,
    //           }),
    //           createAuthenticatedRequest(context),
    //         );
    //         const data = sa.data.data;
    //         return {
    //           id: x.id,
    //           name: x.name,
    //           businessForcast: data,
    //         };
    //       }),
    //   );
    //
    //   allPipelines.push(
    //     (records as Array<PipelineForecasts>).map((x) => ({
    //       id: x.id,
    //       name: x.name,
    //       businessForcast: x.businessForcast,
    //     })),
    //   );
    // }

    // dispatch(updatePipelines({ pipelines: allPipelines.flat() }));
    dispatch(updatePipelines({ pipelines: rawPipelines }));
  };

  const onShareSelectedOpportunitiesClick = () => {
    const rowIds = selectedRecords.map((record) => record.split("-")[0]);
    const uniqueRowIds = uniq(rowIds);
    setShareModalOpen(true);
    setShareModalOpenFor(uniqueRowIds);
  };

  const getFiltersDataForForecastRequest = (): ForecastFiltersProps &
    ForecastExtendedFiltersProps => {
    return {
      ...filters,
      ...Object.fromEntries(
        Object.entries(getValues()) //(extendedFilters)
          .map(([key, value]) => [key, value === null ? "" : value])
          .map(([key, value]) => [
            key,
            Array.isArray(value)
              ? value.map((_v) => _v.value)
              : typeof value.getMonth === "function"
              ? // formatISO(
                //   new Date(
                //     (getEpochDateWithoutTimezone(value) as number) +
                //       (key.toLowerCase().includes("before") ? 36000000 : 0),
                //   ),
                // ).split("+")[0] + "Z"
                getISODateWithAdjustedTimezone(
                  value,
                  false,
                  true,
                  key.toLowerCase().includes("before")
                    ? "T11:00:00+00:00"
                    : "T00:00:00+00:00",
                )
              : value,
          ])
          .filter(([key, value]) => value !== null && value?.length > 0),
      ),
    };
  };

  const handleOpenAddToPipelines = (item: ForecastRecord): void => {
    const { id, rowId } = item;
    if (addToPipelinesOpen) {
      handleCloseAddToPipelines();
      setTimeout(() => {
        dispatch(setPipelinesForRecordFor(rowId));
        dispatch(
          getPipelinesForRecord({
            context,
            table: "forecasts",
            recordId: id,
          }),
        );
      }, 100);
    } else {
      dispatch(setPipelinesForRecordFor(rowId));
      dispatch(
        getPipelinesForRecord({
          context,
          table: "forecasts",
          recordId: id,
        }),
      );
    }
    // setAddToPipelinesOpen(id);
  };

  const handleCloseAddToPipelines = (): void => {
    // dispatch(resetPipelinesForRecord());
    setAddToPipelinesOpen(null);
    dispatch(setPipelinesForRecordFor(null));
    dispatch(resetPipelinesForRecord());
  };

  useEffect(() => {
    if (
      RequestStatus.isDone(pipelinesForRecordFetchStatus) &&
      pipelinesForRecordFor !== null &&
      !addToPipelinesOpen
    )
      setAddToPipelinesOpen(pipelinesForRecordFor);
    if (RequestStatus.isError(pipelinesForRecordFetchStatus)) {
      toast.error(
        "Pipeline data for this row couldn't be loaded. Try again later.",
      );
    }
  }, [pipelinesForRecordFetchStatus, pipelinesForRecordFor]);

  useEffect(() => {
    if (
      !RequestStatus.isNull(fetchStatus) &&
      history.location.search.length === 0
    ) {
      setPreventFiltersEffectCall(true);
    } else
      dispatch(
        getForecasts({
          context,
          params: {
            pageIndex: pagination.page,
            rowsPerPage: pagination.rowsPerPage,
            ...sortOption,
            ...getFiltersDataForForecastRequest(),
          },
        }),
      );
  }, [history.location]);

  useEffect(() => {
    if (preventFiltersEffectCall) {
      setPagination(initialPagination);
      setSortOption(initialSortOption);
      setFilters(initialFilters);
      setExtendedFilters(filterFormDefaultValues);
      reset(filterFormDefaultValues);
      setTimeout(() => setPreventFiltersEffectCall(false), 150);
    }
  }, [preventFiltersEffectCall]);

  useEffect(() => {
    if (!RequestStatus.isNull(fetchStatus))
      history.push(
        `/forecast${buildQuery({
          ...pagination,
          ...sortOption,
          ...getFiltersDataForForecastRequest(),
        })}`,
      );
  }, [pagination.page, pagination.rowsPerPage]);

  useEffect(() => {
    if (!RequestStatus.isNull(fetchStatus) && !preventFiltersEffectCall) {
      if (pagination.page > 0) setPagination((prev) => ({ ...prev, page: 0 }));
      else
        history.push(
          `/forecast${buildQuery({
            ...pagination,
            ...sortOption,
            ...getFiltersDataForForecastRequest(),
          })}`,
        );
    }
  }, [
    sortOption.sortOrder,
    sortOption.sortField,
    filters.OnlyUntrackedInPipeline,
    filters.newOnly,
    filters.liveOnly,
    filters.keyword,
    extendedFilters,
    preventFiltersEffectCall,
  ]);

  useEffect(() => {
    //  load pipeline data
    dispatch(getPipelines(context));
    dispatch(getOrganizationUsers(context));
    dispatch(getTableSettings({ context, key: FORECAST_COLUMNS_SETTINGS_KEY }));

    return () => {
      setColumns([]);
      dispatch(resetTableSettings());
      dispatch(resetSaveAdminDataStatus());
      dispatch(resetShareOpportunitiesStatus());
      dispatch(resetAddOrRemoveFromPipelinesStatus());
      dispatch(resetGetForecasts());
      dispatch(resetPipelinesState());
      dispatch(resetPipelinesForRecord());
    };
  }, []);

  useEffect(() => {
    if (RequestStatus.isError(fetchStatus))
      toast.error(
        "There was an error fetching Forecast+ data. Please try again later.",
      );
  }, [fetchStatus]);

  useEffect(() => {
    if (
      RequestStatus.isDone(tableSettingsFetchStatus) ||
      RequestStatus.isError(tableSettingsFetchStatus)
    ) {
      setColumns(
        getColumnsConfig(isFree, getSortableColumnPropertyName)
          .filter((column) =>
            column?.hiddenBasedOnRole ? canEditPrimes : true,
          )
          .map((column, idx) => ({
            ...column,
            order:
              columnsOrder.find(
                (_column) => _column.propertyName === column.propertyName,
              )?.order ?? idx,
            isVisible:
              columnsOrder.find(
                (_column) => _column.propertyName === column.propertyName,
              )?.isVisible ?? column.isVisible,
            ...(isFree && { sortable: false }),
          }))
          .sort((a, b) => a.order - b.order),
      );
    }
  }, [tableSettingsFetchStatus, canEditPrimes, getSortableColumnPropertyName]);

  useEffect(() => {
    if (RequestStatus.isNull(dropdownOptionsFetchStatus)) {
      dispatch(getForecastFiltersDropdownOptions(context));
    }
  }, [dropdownOptionsFetchStatus]);

  useEffect(() => {
    //it differs from regular fetch status value assignment since this requires updating the pipelines with additional data
    if (RequestStatus.isHalfDone(pipelinesFetchStatus)) {
      adjustPipelinesData();
    } else if (RequestStatus.isError(pipelinesFetchStatus)) {
      toast.error(
        "Couldn't load pipelines details for Forecasts. Try again later.",
      );
      dispatch(resetPipelinesState());
    }
  }, [pipelinesFetchStatus]);

  useEffect(() => {
    if (RequestStatus.isDone(saveAdminEditsFetchStatus)) {
      toast.success("Data updated.");
      dispatch(resetSaveAdminDataStatus());
      if (!editModalOpenFor) return;
      dispatch(
        updateAfterAdminEdit({
          businessForecastId: editModalOpenFor.id,
          subPrimes:
            adminEditGetValues("subPrimes") === defaultEmptyLine
              ? null
              : adminEditGetValues("subPrimes"),
          quarterlyCallNotes:
            adminEditGetValues("quarterlyCallNotes") === defaultEmptyLine
              ? null
              : adminEditGetValues("quarterlyCallNotes"),
          externalLinkHtml:
            adminEditGetValues("externalLinkHtml") === defaultEmptyLine
              ? null
              : adminEditGetValues("externalLinkHtml"),
          externalLinkText:
            adminEditGetValues("externalLinkText") === "\n"
              ? null
              : adminEditGetValues("externalLinkText"),
        }),
      );
      handleCloseEditModal();
    }
    if (RequestStatus.isError(saveAdminEditsFetchStatus))
      toast.error("There was an error updating prime data.");
  }, [saveAdminEditsFetchStatus, editModalOpenFor]);

  useEffect(() => {
    if (RequestStatus.isDone(shareOpportunitiesFetchStatus)) {
      toast.success("Opportunity shared successfully");
      dispatch(resetShareOpportunitiesStatus());
      handleCloseShareModal();
    }
    if (RequestStatus.isError(shareOpportunitiesFetchStatus))
      toast.error("There was an error sharing this opportunity.");
  }, [shareOpportunitiesFetchStatus]);

  useEffect(() => {
    if (!RequestStatus.isNull(addOrRemoveFromPipelinesFetchStatus)) {
      if (RequestStatus.isDone(addOrRemoveFromPipelinesFetchStatus))
        toast.success("Pipelines modified");
      else if (RequestStatus.isError(addOrRemoveFromPipelinesFetchStatus))
        toast.error("There was an error performing this operation.");
      dispatch(getPipelines(context));
      addToPipelinesOpen && handleCloseAddToPipelines();
      bulkAddToPipelinesModalOpen && handleCloseBulkAddToPipelinesModal();
      dispatch(resetAddOrRemoveFromPipelinesStatus());
      dispatch(
        getForecasts({
          context,
          params: {
            pageIndex: pagination.page,
            rowsPerPage: pagination.rowsPerPage,
            ...sortOption,
            ...getFiltersDataForForecastRequest(),
          },
        }),
      );
    }
  }, [addOrRemoveFromPipelinesFetchStatus]);

  const CellDisabledForFreeUsers = (_props: { text: string }): JSX.Element => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        onClick={(e) => e.preventDefault()}
        sx={{
          height: "50px",
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography
          color={"textPrimary"}
          variant={"body2"}
          sx={{
            display: "-webkit-box",
            filter: "blur(5px)",
            "-webkit-user-select": "none",
            "-ms-user-select": "none",
            userSelect: "none",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            "& > *": {
              m: 0,
            },
          }}
          dangerouslySetInnerHTML={{
            __html: _props.text?.replace(/(<([^>]+)>)/gi, "") ?? "",
          }}
        />
      </Box>
      <Button
        sx={{
          position: "absolute",
        }}
        variant={"contained"}
        color={"secondary"}
        size={"small"}
        onClick={() => history.push("/tiers")}
      >
        Upgrade
      </Button>
    </Box>
  );

  return (
    <Box
      sx={{
        p: 2,
        textAlign: "start",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SimpleModal
        open={upgradePlanModalOpen}
        handleClose={() => setUpgradePlanModalOpen(false)}
        title={"You're almost there!"}
        disableCancelButton
        handleAccept={() => setUpgradePlanModalOpen(false)}
      >
        <Typography variant={"subtitle2"} color={"textPrimary"}>
          This feature is disabled for your role. Please{" "}
          <Link to={"/tiers"}>upgrade</Link> to access this feature!
        </Typography>
      </SimpleModal>
      <SimpleModal
        open={editModalOpen}
        handleClose={handleCloseEditModal}
        acceptButtonType={"submit"}
        form={ADMIN_EDIT_FORM_ID}
        title={`Enter Info for ${editModalOpenFor?.awardTitle}`}
        minWidth={"1200px"}
        acceptLabel={"Save"}
        acceptButtonLoading={RequestStatus.isFetching(
          saveAdminEditsFetchStatus,
        )}
      >
        <Box
          sx={{
            display: "flex",
            gap: 4,
          }}
        >
          <form
            id={ADMIN_EDIT_FORM_ID}
            onSubmit={adminEditHandleSubmit(onAdminEditSubmit)}
            style={{
              width: "675px",
            }}
          >
            <Input
              type={"rich-text"}
              name={"subPrimes"}
              control={adminEditControl}
              label={"Likely Primes"}
            />
            <Input
              type={"rich-text"}
              name={"quarterlyCallNotes"}
              control={adminEditControl}
              label={"Quarterly Forecast Q&A and Notes"}
            />
            <Input
              type={"rich-text"}
              name={"externalLinkHtml"}
              control={adminEditControl}
              label={"Links to RFP/RFI"}
              richTextUnformattedOnChange={(value: string) =>
                adminEditSetValue("externalLinkText", value)
              }
            />
          </form>
          <ProjectsTable />
        </Box>
      </SimpleModal>
      <SimpleModal
        open={rfpRfiModalOpen}
        handleClose={handleCloseRfpRfiModal}
        handleAccept={handleCloseRfpRfiModal}
        title={`Link to Bid Docs for ${rfpRfiModalOpenFor?.awardTitle}`}
      >
        {/*{rfpRfiModalOpenFor?.country}*/}
        <div
          dangerouslySetInnerHTML={{
            __html: rfpRfiModalOpenFor?.externalLinkHtml ?? "",
          }}
        />
      </SimpleModal>
      <Drawer
        open={filtersOpen}
        onClose={handleCancelFilters}
        variant={"temporary"}
        anchor={"right"}
        sx={{
          zIndex: constants.zIndexes.drawer,
        }}
        PaperProps={{
          sx: {
            width: "480px",
            "& > div": {
              px: 2,
            },
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
            backgroundColor: theme.palette.secondary.light,
            borderBottom: `solid 3px ${theme.palette.secondary.main}`,
            py: 3,
          }}
        >
          <FiltersIcon />
          <Typography variant={"h5"}>Filters</Typography>
        </Box>
        <Box
          sx={{
            py: 2,
            overflowY: "scroll",
            flex: 1,
          }}
        >
          <form
            onSubmit={handleSubmit(onFiltersSubmit)}
            id={FILTERS_FORM_ID}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <ForecastFilters
              control={control}
              countryOptions={contriesOffices}
              sectorOptions={sectors}
              awardActionTypeOptions={awardTypes}
              geoCodesOptions={geographicalCodes}
            />
          </form>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "16px",
            py: 2,
          }}
        >
          <Button
            variant={"secondaryContained"}
            color={"secondary"}
            onClick={handleCancelFilters}
          >
            Cancel
          </Button>
          <Button
            variant={"secondaryContained"}
            color={"secondary"}
            onClick={handleClearFilters}
          >
            Clear
          </Button>
          <Button
            variant={"contained"}
            color={"secondary"}
            // onClick={handleFilterData}
            type={"submit"}
            form={FILTERS_FORM_ID}
          >
            Save
          </Button>
        </Box>
      </Drawer>
      <ShareModal
        open={shareModalOpen}
        handleClose={handleCloseShareModal}
        openFor={shareModalOpenFor}
        onSubmit={onShareSubmit}
        shareFetchStatus={shareOpportunitiesFetchStatus}
        type={"forecast"}
      />
      <AddMultipleOpportunitiesToPipelinesModal
        itemsIds={bulkAddToPipelinesModalOpenFor ?? []}
        bulkAddToPipelinesModalOpen={bulkAddToPipelinesModalOpen}
        handleCloseBulkAddToPipelinesModal={handleCloseBulkAddToPipelinesModal}
        pipelines={pipelines}
      />

      <Typography variant={"body2"} sx={{ mb: 3 }}>
        This feed monitors changes made to the USAID Business Forcast, including
        new opportunities added, changes made to existing opportunities, and
        when posted opportunities are removed. These changes are color-coded for
        simplified reference: all new opportunities are highlighted in blue;
        removed opportunities are highlighted in gray; changes are highlighted
        in orange on the left column and only the cells that changed are also
        highlighted orange for quicker reference. See historical changes to any
        opportunity by added to "Pipelines" list.
      </Typography>
      {columns.length > 0 ? (
        <CustomTable
          config={{
            data: forecastData.map((item) => {
              return {
                ...item,
                status: item.createdOn ? (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {getStatusPill(item, theme)}
                      {/*<Button*/}
                      {/*  variant={"contained"}*/}
                      {/*  size={"small"}*/}
                      {/*  sx={{*/}
                      {/*    py: 0,*/}
                      {/*    px: 0.5,*/}
                      {/*  }}*/}
                      {/*  startIcon={*/}
                      {/*    <InfoIcon*/}
                      {/*      sx={{*/}
                      {/*        height: "16px !important",*/}
                      {/*        width: "16px !important",*/}
                      {/*      }}*/}
                      {/*    />*/}
                      {/*  }*/}
                      {/*  component={"a"}*/}
                      {/*  href={`/forecast/${item?.id}`}*/}
                      {/*  target={"_blank"}*/}
                      {/*  disabled={!item?.id}*/}
                      {/*>*/}
                      {/*  Details*/}
                      {/*</Button>*/}
                    </Box>
                    <Typography
                      variant={"body2"}
                      color={"textSecondary"}
                      sx={{ mt: 0.5 }}
                    >
                      {format(new Date(item.createdOn), "MM/dd/yyyy")} at{" "}
                      {format(new Date(item.createdOn), "hh:mm aaa")}
                    </Typography>
                  </>
                ) : (
                  <>{getStatusPill(item, theme)}</>
                ),
                actions: (
                  <RowActions
                    onShareClick={() => handleOpenShareModal(item)}
                    item={item}
                    canShare={canShare}
                    isFree={isFree}
                    addToPipelinesOpen={addToPipelinesOpen}
                    setAddToPipelinesOpen={setAddToPipelinesOpen}
                    handleOpenAddToPipelines={handleOpenAddToPipelines}
                    handleCloseAddToPipelines={handleCloseAddToPipelines}
                  />
                ),
                rowBackground:
                  item.status === businessForecastRecordType.FinalRecord
                    ? customColors.forecastRemovedRecordBackground
                    : false,
                rowTextColor:
                  item.status === businessForecastRecordType.FinalRecord
                    ? customColors.forecastRemovedRecordText
                    : false,
                adminActions: (
                  <Tooltip title={"Edit"}>
                    <span>
                      <IconButton
                        sx={{
                          border: "solid 2px",
                          backgroundColor: alpha(
                            theme.palette.success.main,
                            0.1,
                          ),
                          position: "unset",
                        }}
                        color={"success"}
                        onClick={() => handleOpenEditModal(item)}
                      >
                        <EditIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                ),
                primeOrIncumbent: isFree ? (
                  <CellDisabledForFreeUsers
                    text={
                      typeof item?.primeOrIncumbent === "string"
                        ? item?.primeOrIncumbent
                        : ""
                    }
                  />
                ) : (
                  item.primeOrIncumbent
                ),
                quarterlyCallNotes: isFree ? (
                  <CellDisabledForFreeUsers
                    text={item?.quarterlyCallNotes ?? ""}
                  />
                ) : (
                  item.quarterlyCallNotes
                ),
                rfpRfiLink: (
                  <Tooltip
                    title={
                      isFree
                        ? "Upgrade your plan to see link to bid docs"
                        : "Link to Bid Docs"
                    }
                  >
                    <span>
                      <IconButton
                        color={"primary"}
                        sx={{
                          border: "solid 2px",
                          backgroundColor: alpha(
                            theme.palette.primary.main,
                            0.1,
                          ),
                          position: "unset",
                        }}
                        onClick={() => handleOpenRfpRfiModal(item)}
                        disabled={!item.externalLinkHtml || isFree}
                      >
                        <DescriptionOutlinedIcon fontSize={"small"} />
                      </IconButton>
                    </span>
                  </Tooltip>
                ),
                disableCellHighlight:
                  item.status !== businessForecastRecordType.NewRecord &&
                  item.status !== businessForecastRecordType.ModifiedRecord,
              };
            }),
            selectedRowsBulkActions: (
              <>
                {!isFree && (
                  <Button
                    variant={"outlined"}
                    color={"success"}
                    sx={{
                      backgroundColor: alpha(theme.palette.success.main, 0.1),
                    }}
                    startIcon={
                      <img
                        src={icons.addToPipeline}
                        alt="map"
                        style={{
                          width: "26px",
                          height: "26px",
                          marginTop: "2px",
                          filter: "hue-rotate(270deg) brightness(0.8)",
                        }}
                      />
                    }
                    onClick={handleOpenBulkAddToPipelinesModal}
                  >
                    Add to pipeline
                  </Button>
                )}
                {canShare && (
                  <Button
                    variant={"outlined"}
                    sx={{
                      backgroundColor: alpha(theme.palette.primary.main, 0.1),
                    }}
                    startIcon={
                      <ReplyIcon
                        fontSize={"small"}
                        sx={{
                          // transform: "rotate(180deg)",
                          transform: "scale(-1, 1)",
                          width: "28px",
                          height: "28px",
                        }}
                      />
                    }
                    onClick={onShareSelectedOpportunitiesClick}
                  >
                    Share
                  </Button>
                )}
              </>
            ),
            stickyColumns: stickyColumns,
            columns: columns,
            cellHighlight: {
              highlightCellAttributeName: "changedColumns",
              highlightColor: theme.palette.secondary.light,
              highlightUnderlineColor: theme.palette.secondary.main,
            },
            header: getTableHeader(theme, {
              handleOpenFilters,
              setFilters,
              liveTally: totalLiveRecords,
              lastUpdated: lastUpdatedUtc
                ? lastUpdatedUtc &&
                  new Date(lastUpdatedUtc).toISOString().startsWith("0")
                  ? "N/A"
                  : `${format(
                      lastUpdatedUtc.endsWith("Z")
                        ? new Date(lastUpdatedUtc)
                        : new Date(`${lastUpdatedUtc}Z`),
                      "dd MMMM yyyy, HH:mm",
                    )}`
                : "N/A",
              fetchStatus: fetchStatus,
              inputValue: keywordSearchInputValue,
              onChange: onKeywordChange,
              allFilters: getFilterValuesFromURL(),
              isFree,
            }),
            columnsConfiguration: {
              allowColumnsConfiguration: true,
              onSaveColumnsConfiguration: handleSaveColumnsConfiguration,
              columnsSettingsKey: FORECAST_COLUMNS_SETTINGS_KEY,
            },
            exportExcelFunc: isFree
              ? async () => setUpgradePlanModalOpen(true)
              : async () =>
                  generateExcel(
                    "Forecasts",
                    `Aidkonekt_forecast_plus_${new Date().getFullYear()}`,
                    getExportableDataTable(
                      getExcelData
                        ? await getExcelData(
                            context,
                            getFiltersDataForForecastRequest(),
                            total,
                          )
                        : forecastData,
                    ),
                  ),
          }}
          parametersToSelectBy={["rowId", "id"]}
          loading={
            RequestStatus.isFetching(fetchStatus) ||
            RequestStatus.isFetching(pipelinesFetchStatus) ||
            RequestStatus.isHalfDone(pipelinesFetchStatus) ||
            RequestStatus.isFetching(tableSettingsFetchStatus)
          }
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          pagination={{ ...pagination, total }}
          bulkActions={{
            allowBulkActions: canShare || !isFree,
            selectedRecords: selectedRecords,
            setSelectedRecords: setSelectedRecords,
          }}
          onSortClick={(
            column: DataColumnConfigProps | StickyColumnConfigProps,
          ) =>
            setSortOption((prev) => {
              const propertyName = getSortableColumnPropertyName(
                column.propertyName,
              );
              return prev.sortField === propertyName
                ? prev.sortOrder === 0
                  ? { sortField: propertyName, sortOrder: 1 }
                  : prev.sortOrder === 1
                  ? { sortField: propertyName, sortOrder: -1 }
                  : { sortField: "", sortOrder: 0 }
                : { sortField: propertyName, sortOrder: 1 };
            })
          }
          currentSort={sortOption}
          paginationDisabled={{
            disabled: isFree,
            disabledMessage: `Showing 20 out of ${total} records - upgrade for full access!`,
          }}
          disableOpenCellDetailsModal={!!addToPipelinesOpen}
        />
      ) : (
        <Card
          sx={{
            display: "flex",
            p: 4,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Card>
      )}
    </Box>
  );
};

export default withRouter(ForecastScreen);
