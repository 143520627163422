import { SummaryGrant } from "../../slices/grants";
import { TableProperties } from "exceljs";
import {
  buildExcelTable,
  genEmptyRow,
  getChunksFromString,
  MaxCharsAllowed,
} from "../../services/exporter";
import { removeHtmlTags } from "../../services/dataParser";
import {
  DateFormat,
  formatDate,
  getNewDateNoTimezoneAdjustment,
} from "../../utils/conversion/date-converters";
import { ForecastRecord } from "../../utils/types/Forecast";
import axios from "axios";
import {
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../configs/axios-export.custom";
import { buildQuery } from "../../utils/Helpers/queryBuilder";
import { currencyFormatterNoDecimals } from "../USAidScreen/USAid";
import { format } from "date-fns";

export const getLabelForMechanism = (
  awardMechanism: string,
  mechanismsOptions: any[],
): string => {
  const mech = mechanismsOptions
    ? mechanismsOptions.find((m) => m.value == awardMechanism)
    : null;
  return mech ? mech.label : "";
};

export const getExportableDataTable = (
  data: any[],
  mechanismsOptions: any[],
  isFA: boolean,
): TableProperties => {
  const UScolumns = [
    { name: "Country" },
    { name: "Project Number" },
    { name: "Project Name" },
    { name: "Project Description" },
    { name: "Implementer" },
    { name: "Subawards" },
    { name: "Award Mechanism" },
    { name: "Awarding Agency Name" },
    { name: "Awarding Office" },
    { name: "Parent IDV Agency Name" },
    { name: "Set-Aside Type" },
    { name: "NAICS Code" },
    { name: "Start Date" },
    { name: "Current End Date" },
    { name: "Potential End Date" },
    { name: "Obligated Amount" },
    { name: "Current Amount" },
    { name: "Potential Amount" },
    { name: "Recipient Address" },
    { name: "Project Profile Link" },
  ];

  const faColumns = [
    { name: "Country" },
    { name: "Project Number" },
    { name: "Project Name" },
    { name: "Project Description" },
    { name: "Implementer" },

    { name: "Technical Sector" },
    { name: "Managing Agency" },
    { name: "Funding Agency" },
    { name: "Funding Account" },
    { name: "Activity Start Date" },
    { name: "Activity End Date" },
    { name: "Obligations" },
    { name: "Disbursements" },

    { name: "Project Profile Link" },
  ];

  const columns = isFA ? faColumns : UScolumns;

  let rows: any[][] = [];

  if (data.length == 0) {
    rows.push(genEmptyRow(columns.length));
  } else {
    for (let i = 0; i < data.length; i++) {
      const record = data[i];
      const chunkDescription = getChunksFromString(
        record.projectDescription,
        MaxCharsAllowed,
      );
      if (isFA) {
        rows.push([
          record.country,
          record.projectNumber?.toLowerCase() === "null"
            ? ""
            : record.projectNumber,
          record.projectName,
          chunkDescription[0] || "",
          record.implementer,
          record.technicalSector,
          record.managingAgency,
          record.fundingAgency,
          record.fundingAccount,
          // formatDate(record.activityStartDate, DateFormat.dd_MMMM_yyyy),
          !record.activityStartDate ||
          new Date(record.activityStartDate).toISOString().startsWith("0")
            ? "N/A"
            : format(
                getNewDateNoTimezoneAdjustment(record.activityStartDate),
                "MM/dd/yyyy",
              ),
          // formatDate(record.activityEndDate, DateFormat.dd_MMMM_yyyy),
          !record.activityEndDate ||
          new Date(record.activityEndDate).toISOString().startsWith("0")
            ? "N/A"
            : format(
                getNewDateNoTimezoneAdjustment(record.activityEndDate),
                "MM/dd/yyyy",
              ),
          record.obligations !== null
            ? currencyFormatterNoDecimals.format(Math.round(record.obligations))
            : "",
          record.disbursements !== null
            ? currencyFormatterNoDecimals.format(
                Math.round(record.disbursements),
              )
            : "",
          `${
            window.location.origin ?? "https://aidkonekt.com"
          }/project-view/record?projectNumber=${record?.projectNumber}&fa=true`,
        ]);
      } else {
        rows.push([
          record.country,
          record.projectNumber?.toLowerCase() === "null"
            ? ""
            : record.projectNumber,
          record.projectName,
          chunkDescription[0] || "",
          record.implementer,
          record.subawardRecipients ?? "",
          getLabelForMechanism(record.awardMechanism, mechanismsOptions),
          record.awardingAgencyName,
          record.awardingOffice,
          record.parentIDVAgencyName,
          record.setAside,
          record.naicsCode,
          !record.startDate ||
          new Date(record.startDate).toISOString().startsWith("0")
            ? "N/A"
            : format(
                getNewDateNoTimezoneAdjustment(record.startDate),
                "MM/dd/yyyy",
              ),
          !record.currentEndDate ||
          new Date(record.currentEndDate).toISOString().startsWith("0")
            ? "N/A"
            : format(
                getNewDateNoTimezoneAdjustment(record.currentEndDate),
                "MM/dd/yyyy",
              ),
          !record.potentialEndDate ||
          new Date(record.potentialEndDate).toISOString().startsWith("0")
            ? "N/A"
            : format(
                getNewDateNoTimezoneAdjustment(record.potentialEndDate),
                "MM/dd/yyyy",
              ),
          // formatDate(record.startDate, DateFormat.dd_MMMM_yyyy),
          // formatDate(record.currentEndDate, DateFormat.dd_MMMM_yyyy),
          // formatDate(record.potentialEndDate, DateFormat.dd_MMMM_yyyy),
          record.obligatedAmount !== null
            ? currencyFormatterNoDecimals.format(
                Math.round(record.obligatedAmount),
              )
            : "",
          record.currentAmount !== null
            ? currencyFormatterNoDecimals.format(
                Math.round(record.currentAmount),
              )
            : "",
          record.potentialAmount !== null
            ? currencyFormatterNoDecimals.format(
                Math.round(record.potentialAmount),
              )
            : "",
          record.recipientAddress,
          `${
            window.location.origin ?? "https://aidkonekt.com"
          }/project-view/record?id=${record.id}&fa=false`,
        ]);
      }

      for (let j = 1; j < chunkDescription.length; j++) {
        let emptyRow = genEmptyRow(columns.length);
        emptyRow[3] = chunkDescription[j];
        rows.push(emptyRow);
      }
    }
  }

  return buildExcelTable("ProjectsExport", columns, rows);
};

export const getExcelDataUsaSpending = async (
  context,
  queryDto,
  totalItems: number = 1000,
): Promise<any[]> => {
  const q = queryDto;
  if (q) {
    q.pageSize = totalItems;
    q.pageIndex = 0;
  }
  let result = Array<any>();
  await axios
    .get(
      getFullUrl(`/api/usaspending${buildQuery(q, "|")}`, {
        useDedicatedEnvironment: true,
      }),
      createRequestWithAuthHeaders(context),
    )
    .then((response) => {
      result = response.data.data;
    })
    .catch((error) => {
      alert("error fetching data");
    });
  return result;
};

export const getExcelDataForeignAssistance = async (
  context,
  queryDto,
  totalItems: number = 1000,
): Promise<any[]> => {
  const q = queryDto;
  if (q) {
    q.pageSize = totalItems;
    q.pageIndex = 0;
  }
  let result = Array<any>();
  await axios
    .get(
      getFullUrl(`/api/ForeignAssistance${buildQuery(q, "|")}`, {
        useDedicatedEnvironment: true,
      }),
      createRequestWithAuthHeaders(context),
    )
    .then((response) => {
      result = response.data.data;
    })
    .catch((error) => {
      alert("error fetching data");
    });
  return result;
};
