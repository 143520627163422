import React, { FC, useContext, useEffect, useState } from "react";
import {
  alpha,
  Box,
  Card,
  CircularProgress,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import SimpleModal from "../../components/Modals/SimpleModal";
import { RootState, useDispatch, useSelector } from "../../store";
import {
  clearDetails,
  ContractsFileRecord,
  getContractDetails,
} from "../../slices/contracts";
import UserContext from "../../services/UserContext";
import { RequestStatus } from "../../utils/Helpers/fetchStatus";
import FileListItem from "../../components/Widgets/FileListItem";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";
import {
  createAuthenticatedRequest,
  getFullUrl,
} from "../../configs/axios-export.custom";
import { saveAs } from "file-saver";
import toast from "react-hot-toast";
import CustomTable from "../../components/MaterialTable/Table";
import {
  getContractsColumnsConfig,
  getContractsStickyColumnsConfig,
} from "./contractsTableColumns.config";
import { getSortableColumnPropertyName } from "./contractsUtils";
import RowActions from "./RowActions";
import { businessForecastRecordType } from "../../utils/types/Forecast";
import LinkIcon from "@mui/icons-material/Link";
import ContactInfo from "./ContactInfo";
import { ContractsResourceServer } from "../../configs/resources";
import { useHistory } from "react-router-dom";
import { extractDataFromSearchParams } from "../../utils/Helpers/extractDataFromSearchParams";

interface ContractsDetailsAndFilesModalProps {
  open: boolean;
  openFor: any;
  handleCloseDetailsModal: () => void;
}

interface SortOption {
  sortField: string;
  label: string;
  sortOrder: number;
}

const sortOptions: Array<SortOption> = [
  { sortField: "createdOn", label: "Most recent", sortOrder: -1 },
  { sortField: "createdOn", label: "Oldest", sortOrder: 1 },
  { sortField: "name", label: "Name: A → Z", sortOrder: 1 },
  { sortField: "name", label: "Name: Z → A", sortOrder: -1 },
  { sortField: "disable-sort", label: "Disable sorting", sortOrder: 0 },
];

const ContractsDetailsAndFilesModal: FC<ContractsDetailsAndFilesModalProps> = (
  props,
) => {
  const { open, openFor, handleCloseDetailsModal } = props;

  const dispatch = useDispatch();
  const context = useContext(UserContext);
  const MUITheme = useTheme();
  const history = useHistory();

  const { fetchStatus, files, matchingFiles, records } = useSelector(
    (state: RootState) => state.contracts.details,
  );

  const [selectedTab, setSelectedTab] = useState<"files" | "recordHistory">(
    "files",
  );
  const [selectedSortOption, setSelectedSortOption] = useState<SortOption>(
    sortOptions[0],
  );
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const handleDownloadAll = async () => {
    setIsDownloading(true);
    axios
      .post(
        getFullUrl(
          `/api/contract/details/${openFor.contractOpportunityBase_Id}/getzip`,
          { useDedicatedEnvironment: true },
        ),
        {},
        {
          ...createAuthenticatedRequest(context),
          responseType: "blob",
        } as any,
      )
      .then(async (response) => {
        let fileName = `export-${new Date().valueOf()}.zip`;
        try {
          fileName = response.headers["content-disposition"]
            .split(";")[1]
            .replace("filename=", "")
            .replaceAll('"', "")
            .trim();
        } catch {
          /* NO-OP */
        }

        // in deployed version the response is still getting converted back
        // to b64 so need to un-load that. if running web server locally the
        // response is already decoded so can directly save response.data instead
        // of this BS
        // const decoded = atob(await response.data.text());
        // const array = new Uint8Array(decoded.length);
        // for (let i = 0; i < decoded.length; i++) {
        //   array[i] = decoded.charCodeAt(i);
        // }

        // saveAs(
        //   new Blob([array], { type: "application/zip" }),
        //   fileName
        // );

        saveAs(new Blob([response.data]), fileName);
        setIsDownloading(false);
      })
      .catch((error) => {
        toast.error("Error fetching data.");
        setIsDownloading(false);
      });
  };

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setSelectedTab("files");
        dispatch(clearDetails());
      }, 150);
    } else {
      if (openFor?.id) {
        const data = extractDataFromSearchParams(history.location.search, [
          "fileKeyword",
          "fileName",
        ]);
        dispatch(
          getContractDetails({
            context,
            params: {
              // id: openFor.id,
              id: openFor.contractOpportunityBase_Id,
              isCombinedDatasetRecord: true,
              ...data,
            },
          }),
        );
      }
    }
  }, [open]);

  return (
    <SimpleModal
      open={open}
      handleClose={handleCloseDetailsModal}
      title={"Opportunity Details"}
      minWidth={"800px"}
      loading={RequestStatus.isFetching(fetchStatus)}
      disableCancelButton
      handleAccept={handleCloseDetailsModal}
      acceptLabel={"Close"}
    >
      <Card>
        <Box
          sx={{
            minHeight: "144px",
            display: "flex",
          }}
        >
          {openFor !== null && (
            <CustomTable
              config={{
                columns: getContractsColumnsConfig(
                  getSortableColumnPropertyName,
                ),
                stickyColumns: getContractsStickyColumnsConfig(false, true),
                data: [openFor].map((item) => ({
                  ...item,
                  actions: (
                    <Tooltip title={"Open in new tab"}>
                      <span>
                        <IconButton
                          sx={{
                            border: "solid 2px",
                            backgroundColor: alpha(
                              MUITheme.palette.primary.main,
                              0.1,
                            ),
                          }}
                          color={"primary"}
                          component={"a"}
                          href={`https://sam.gov/opp/${item.id}/view`}
                          target={"_blank"}
                          rel={"noreferrer noopener"}
                        >
                          <LinkIcon sx={{ width: "24px", height: "24px" }} />
                        </IconButton>
                      </span>
                    </Tooltip>
                  ),
                  contactInfo: ContactInfo({ data: item.pointOfContactOne }),
                  secondaryContactInfo: ContactInfo({
                    data: item.pointOfContactTwo,
                  }),
                })),
                header: <Box></Box>,
                allowFullscreen: false,
                rowHeight: 82,
              }}
              TableContainerComponent={Box}
              tableContainerComponentSx={{
                mt: "-24px",
                mb: "16px",
                border: `solid 1px ${MUITheme.palette.divider}`,
              }}
              loading={false}
              onPageChange={() => {}}
              onRowsPerPageChange={() => {}}
              pagination={{
                page: 0,
                rowsPerPage: 0,
                total: 0,
              }}
              paginationDisabled={{
                disabled: true,
                disabledMessage: "",
              }}
            />
          )}
        </Box>
        <Tabs value={selectedTab}>
          <Tab
            color={"primary"}
            onClick={() => selectedTab !== "files" && setSelectedTab("files")}
            value={"files"}
            label={"Attachments"}
          />
          <Tab
            color={"primary"}
            onClick={() =>
              selectedTab !== "recordHistory" && setSelectedTab("recordHistory")
            }
            value={"recordHistory"}
            label={"sam.gov history"}
          />
        </Tabs>
        <Divider />
        <Box sx={{ py: 2, ...(selectedTab === "recordHistory" && { pb: 0 }) }}>
          {selectedTab === "files" ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                }}
              >
                <Button
                  variant={"contained"}
                  endIcon={
                    <>
                      {" "}
                      <DownloadIcon
                        sx={{
                          height: "24px !important",
                          width: "24px !important",
                        }}
                      />
                      {isDownloading && (
                        <CircularProgress size={16} sx={{ color: "white" }} />
                      )}
                    </>
                  }
                  sx={{
                    height: "56px",
                  }}
                  onClick={handleDownloadAll}
                  disabled={isDownloading || !files.length}
                >
                  Download All
                </Button>

                <TextField
                  select
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={`${selectedSortOption.sortField}-${selectedSortOption.sortOrder}`}
                  placeholder={"Select sort option"}
                  label={"Sort"}
                  sx={{
                    minWidth: "250px",
                  }}
                  onChange={(e) =>
                    setSelectedSortOption(() => {
                      const option =
                        sortOptions.find(
                          (option) =>
                            e.target.value ===
                            `${option.sortField}-${option.sortOrder}`,
                        ) ?? sortOptions[0];

                      return option;
                    })
                  }
                >
                  {sortOptions.map((option, idx) => (
                    <MenuItem
                      value={`${option.sortField}-${option.sortOrder}`}
                      key={idx}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box
                sx={{
                  height: "348px",
                  overflowY: "scroll",
                  my: 1,
                }}
              >
                {files.length > 0 ? (
                  [...files]
                    .sort((a: ContractsFileRecord, b: ContractsFileRecord) =>
                      selectedSortOption.sortField === "disable-sort"
                        ? 0
                        : a[selectedSortOption.sortField] >
                          b[selectedSortOption.sortField]
                        ? selectedSortOption.sortOrder
                        : -selectedSortOption.sortOrder,
                    )
                    .map((file, idx) => (
                      <FileListItem
                        file={{
                          ...file,
                          uri:
                            file?.uri ??
                            `${ContractsResourceServer}${encodeURIComponent(
                              file.name,
                            )}`,
                        }}
                        highlight={{
                          show: matchingFiles.includes(file.name),
                          text: "(Keyword match in document)",
                        }}
                        source={"contracts"}
                      />
                    ))
                ) : RequestStatus.isDone(fetchStatus) ? (
                  <Typography variant={"h6"} color={"textSecondary"}>
                    No files found
                  </Typography>
                ) : (
                  RequestStatus.isFetching(fetchStatus) && (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress />
                    </Box>
                  )
                )}
              </Box>
            </>
          ) : (
            <Box
              sx={{
                minHeight: "438px",
                display: "flex",
              }}
            >
              <CustomTable
                config={{
                  columns: getContractsColumnsConfig(
                    getSortableColumnPropertyName,
                    true,
                  ),
                  stickyColumns: getContractsStickyColumnsConfig(false, true),
                  data: records.map((item) => ({
                    ...item,
                    actions: (
                      <Tooltip title={"Open in new tab"}>
                        <span>
                          <IconButton
                            sx={{
                              border: "solid 2px",
                              backgroundColor: alpha(
                                MUITheme.palette.primary.main,
                                0.1,
                              ),
                            }}
                            color={"primary"}
                            component={"a"}
                            href={`https://sam.gov/opp/${item.id}/view`}
                            target={"_blank"}
                            rel={"noreferrer noopener"}
                          >
                            <LinkIcon sx={{ width: "24px", height: "24px" }} />
                          </IconButton>
                        </span>
                      </Tooltip>
                    ),
                    contactInfo: ContactInfo({ data: item.pointOfContactOne }),
                    secondaryContactInfo: ContactInfo({
                      data: item.pointOfContactTwo,
                    }),
                  })),
                  header: <Box></Box>,
                  allowFullscreen: false,
                }}
                TableContainerComponent={Box}
                tableContainerComponentSx={{
                  mt: "-24px",
                  mb: "16px",
                }}
                loading={RequestStatus.isFetching(fetchStatus)}
                onPageChange={() => {}}
                onRowsPerPageChange={() => {}}
                pagination={{
                  page: 0,
                  rowsPerPage: 0,
                  total: 0,
                }}
                paginationDisabled={{
                  disabled: true,
                  disabledMessage: "",
                }}
              />
            </Box>
          )}
        </Box>
      </Card>
    </SimpleModal>
  );
};

export default ContractsDetailsAndFilesModal;
