import { DataColumnConfigProps } from "../../components/MaterialTable/Table";
import { format } from "date-fns";

export const currencyFormatterNoDecimals = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const currencyFormatterWithDecimals = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const getStickyColumnsConfig = () => [
  {
    propertyName: "view",
    label: "Actions",
    order: 0,
    isVisible: true,
    sortable: false,
    width: 90,
    isSticky: true,
    disableModalWithDetails: true,
  },
];

export const getUsaSpendingColumnsConfig = (
  getSortableColumnPropertyName: (_propertyName: string) => string,
): Array<DataColumnConfigProps> => [
  {
    propertyName: "country",
    label: "Country",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 300,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "projectNumber",
    label: "Project Number",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 200,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "projectName",
    label: "Project Name",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 300,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "projectDescription",
    label: "Description",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 300,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "implementer",
    label: "Implementer",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 200,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "subawardRecipients",
    label: "Subawards",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 250,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "awardMechanism",
    label: "Award Mechanism",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 250,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "awardingAgencyName",
    label: "Award Funding Agency",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 300,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "awardingOffice",
    label: "Awarding Office",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 250,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "parentIDVAgencyName",
    label: "Parent IDV Agency Name",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 250,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "setAside",
    label: "Set-Aside Type",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 250,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "naicsCode",
    label: "NAICS Code",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 250,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "startDate",
    label: "Start Date",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 250,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
    cellFormatter: (value) =>
      new Date(value) && new Date(value).toISOString().startsWith("0")
        ? "N/A"
        : format(new Date(value), "dd MMMM yyyy"),
    disableModalWithDetails: true,
  },
  {
    propertyName: "currentEndDate",
    label: "Current End Date",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 250,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
    cellFormatter: (value) =>
      new Date(value) && new Date(value).toISOString().startsWith("0")
        ? "N/A"
        : format(new Date(value), "dd MMMM yyyy"),
    disableModalWithDetails: true,
  },
  {
    propertyName: "potentialEndDate",
    label: "Potential End Date",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 250,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
    cellFormatter: (value) =>
      new Date(value) && new Date(value).toISOString().startsWith("0")
        ? "N/A"
        : format(new Date(value), "dd MMMM yyyy"),
    disableModalWithDetails: true,
  },
  {
    propertyName: "obligatedAmount",
    label: "Obligated Amount",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 250,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
    cellFormatter: (value) =>
      value !== null
        ? currencyFormatterNoDecimals.format(Math.round(value as number))
        : "",
    disableModalWithDetails: true,
  },
  {
    propertyName: "currentAmount",
    label: "Current Amount",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 250,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
    cellFormatter: (value) =>
      value !== null
        ? currencyFormatterNoDecimals.format(Math.round(value as number))
        : "",
    disableModalWithDetails: true,
  },
  {
    propertyName: "potentialAmount",
    label: "Potential Amount",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 250,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
    cellFormatter: (value) =>
      value !== null
        ? currencyFormatterNoDecimals.format(Math.round(value as number))
        : "",
    disableModalWithDetails: true,
  },
  {
    propertyName: "recipientAddress",
    label: "Recipient Address",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 250,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
];

export const getForeignAssistanceColumnsConfig = (
  getSortableColumnPropertyName: (_propertyName: string) => string,
): Array<DataColumnConfigProps> => [
  {
    propertyName: "country",
    label: "Country",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 200,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "projectName",
    label: "Project Name",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 300,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "projectNumber",
    label: "Project Number",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 200,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "projectDescription",
    label: "Project Description",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 300,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "implementer",
    label: "Implementer",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 200,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "technicalSector",
    label: "Technical Sector",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 300,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "managingAgency",
    label: "Managing Agency",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 300,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "fundingAgency",
    label: "Funding Agency",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 300,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "fundingAccount",
    label: "Funding Account",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 300,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "activityStartDate",
    label: "Activity Start Date",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 250,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
    cellFormatter: (value) =>
      new Date(value) && new Date(value).toISOString().startsWith("0")
        ? "N/A"
        : format(new Date(value), "dd MMMM yyyy"),
    disableModalWithDetails: true,
  },
  {
    propertyName: "activityEndDate",
    label: "Activity End Date",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 250,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
    cellFormatter: (value) =>
      new Date(value) && new Date(value).toISOString().startsWith("0")
        ? "N/A"
        : format(new Date(value), "dd MMMM yyyy"),
    disableModalWithDetails: true,
  },
  {
    propertyName: "obligations",
    label: "Obligations",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 250,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
    cellFormatter: (value) =>
      value !== null
        ? currencyFormatterNoDecimals.format(Math.round(value as number))
        : "",
    disableModalWithDetails: true,
  },
  {
    propertyName: "disbursements",
    label: "Disbursements",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 250,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
    cellFormatter: (value) =>
      value !== null
        ? currencyFormatterNoDecimals.format(Math.round(value as number))
        : "",
    disableModalWithDetails: true,
  },
];
