import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useRef,
  useState,
} from "react";
import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import { AttachFile } from "@mui/icons-material";
import SeverityPill from "../../../components/Widgets/SeverityPill";
import { format } from "date-fns";
import Button from "@mui/material/Button";
import { getStatusPill } from "../../ForecastV2/forecastUtils";
import { ForecastRecord } from "../../../utils/types/Forecast";
import ReplyIcon from "@mui/icons-material/Reply";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import Stack from "@mui/material/Stack";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import BusinessIcon from "@mui/icons-material/Business";
import AddToPipelinesTooltip from "../../ForecastV2/AddToPipelinesTooltip";

interface DetailsRowProps {
  item: any;
  idx: number;
  handleOpenModal: ((item: any) => void) | null;
  setShareModalOpen: Dispatch<SetStateAction<boolean>>;
  setShareModalOpenFor: Dispatch<SetStateAction<any>>;
  handleOpenAddToPipelines: (item: any, index: number) => void;
  handleCloseAddToPipelines: () => void;
  addToPipelinesOpen: number | string | null;
  onChangePipelines?: (
    addedToPipelinesIds: number[],
    removedFromPipelinesIds: number[],
    currentPipelinesIds: number[],
    item: any,
  ) => void;
}

const DetailsRow: FC<DetailsRowProps> = (props) => {
  const {
    item,
    idx,
    handleOpenModal,
    setShareModalOpen,
    setShareModalOpenFor,
    handleOpenAddToPipelines,
    handleCloseAddToPipelines,
    addToPipelinesOpen,
    onChangePipelines,
  } = props;

  // const { items } = useSelector((state: RootState) => state.pipelines);
  //
  // const pipelinesIds = useMemo(() => items.map((item) => item.id), [items]);
  //
  // const pipelines = useMemo(
  //   () =>
  //     item.pipelines.filter((pipeline) =>
  //       pipelinesIds.includes(pipeline.pipelineId),
  //     ),
  //   [pipelinesIds, item],
  // );

  const theme = useTheme();

  const [isTruncated, setIsTruncated] = useState<boolean>(true);

  const descriptionRef = useRef<HTMLDivElement>(null);

  const handleReadMore = useCallback(() => {
    setIsTruncated(!isTruncated);
  }, [isTruncated]);

  const shouldShowReadMore = (): boolean => {
    if (!descriptionRef.current || !isTruncated) return true;
    return (
      descriptionRef.current.clientHeight < descriptionRef.current.scrollHeight
    );
  };

  const handleOpenShareModal = (item: ForecastRecord | Array<string>): void => {
    setShareModalOpen(true);
    setShareModalOpenFor(item);
  };

  return (
    <Box
      sx={{
        ...(idx > 0 && {
          borderTop: `solid 1px ${theme.palette.divider}`,
        }),
        px: 2,
        py: 1,
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          columnGap: 1,
          rowGap: 0.5,
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            columnGap: 1,
            rowGap: 0.5,
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          {item.datasetType === "Forecast"
            ? getStatusPill(
                {
                  ...item,
                  status:
                    item.status === "Removed"
                      ? "FinalRecord"
                      : item.status === "Changed"
                      ? "ModifiedRecord"
                      : "NewRecord",
                } as ForecastRecord,
                theme,
                "8px",
                28,
              )
            : getStatusPill(
                {
                  ...item,
                  status:
                    item.lastUpdatedOn !== item.postingDate
                      ? "ModifiedRecord"
                      : "NewRecord",
                } as ForecastRecord,
                theme,
                "8px",
                28,
              )}
          <SeverityPill
            label={item.badgeSettings.label}
            height={28}
            fontSize={"14px"}
            color={theme.palette.secondary.contrastText}
            backgroundColor={item.badgeSettings.backgroundColor}
            borderRadius={"8px"}
            fontWeight={300}
            // boxShadow={`2px 2px 11px -4px ${item.badgeSettings.boxShadowColor}`}
            icon={item.badgeSettings.icon}
            sx={{
              "& > svg": {
                fill: theme.palette.secondary.contrastText,
                height: "16px !important",
                width: "16px !important",
              },
              px: 0.5,
            }}
          />
          <Typography variant={"body2"} color={"textSecondary"}>
            {item.lastUpdatedOn
              ? format(
                  new Date(item.lastUpdatedOn),
                  "MM/dd/yyyy 'at' hh:mm aaa",
                )
              : ""}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Tooltip
            onClose={handleCloseAddToPipelines}
            open={
              !!addToPipelinesOpen &&
              addToPipelinesOpen === `${item.sourceId}-${idx}`
            }
            disableFocusListener
            disableHoverListener
            disableTouchListener
            disableInteractive={false}
            // PopperProps={{
            //   disablePortal: false,
            //   sx: {
            //     "&[data-popper-reference-hidden]": {
            //       // visibility: "hidden",
            //       // "pointer-events": "none",
            //     },
            //   },
            // }}
            title={
              <AddToPipelinesTooltip
                handleCloseAddToPipelines={handleCloseAddToPipelines}
                item={{ ...item, id: item.sourceId }}
                source={
                  item.datasetType === "Forecast"
                    ? "forecast"
                    : item.datasetType === "Contract"
                    ? "contracts"
                    : "grants"
                }
                onChangePipelines={onChangePipelines}
              />
            }
            slotProps={{
              tooltip: {
                sx: {
                  backgroundColor: "rgb(97,97,97)",
                  minWidth: "350px",
                },
              },
            }}
          >
            <span>
              <SeverityPill
                label={item.userPipelines?.length > 0 ? "Added" : "Add"}
                height={28}
                fontSize={"14px"}
                color={
                  item.userPipelines?.length > 0
                    ? theme.palette.secondary.contrastText
                    : theme.palette.success.main
                }
                backgroundColor={
                  item.userPipelines?.length > 0
                    ? theme.palette.success.main
                    : theme.palette.primary.contrastText
                }
                borderRadius={"8px"}
                fontWeight={300}
                icon={
                  <CreateNewFolderOutlinedIcon
                    fontSize={"small"}
                    sx={{
                      width: "24px",
                      height: "24px",
                    }}
                  />
                }
                sx={{
                  "& > svg": {
                    fill:
                      item.userPipelines?.length > 0
                        ? theme.palette.secondary.contrastText
                        : theme.palette.success.main,
                    height: "16px !important",
                    width: "16px !important",
                  },
                  alignItems: "center",
                  px: 0.5,
                  ...(!item.userPipelines?.length && {
                    outline: `solid 1px ${theme.palette.success.main}`,
                  }),
                }}
                onClick={() => handleOpenAddToPipelines(item, idx)}
              />
            </span>
          </Tooltip>
          <SeverityPill
            label={"Share"}
            height={28}
            fontSize={"14px"}
            color={theme.palette.secondary.contrastText}
            backgroundColor={theme.palette.primary.main}
            borderRadius={"8px"}
            fontWeight={300}
            // boxShadow={`2px 2px 11px -4px ${item.badgeSettings.boxShadowColor}`}
            icon={
              <ReplyIcon
                fontSize={"small"}
                sx={{
                  // transform: "rotate(180deg)",
                  transform: "scale(-1, 1)",
                  width: "24px",
                  height: "24px",
                }}
              />
            }
            sx={{
              "& > svg": {
                fill: theme.palette.secondary.contrastText,
                height: "16px !important",
                width: "16px !important",
              },
              alignItems: "center",
              px: 0.5,
            }}
            onClick={() => handleOpenShareModal(item)}
          />
        </Box>
      </Box>
      <Stack direction={"row"} alignItems={"center"} gap={2}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          sx={{
            maxWidth: "75%",
          }}
        >
          <LocationOnOutlinedIcon
            sx={{
              height: "16px !important",
              width: "16px !important",
            }}
          />
          <Typography
            variant={"subtitle2"}
            fontWeight={300}
            color={"inherit"}
            sx={{
              mb: "-2px",
              width: "max-content",
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
              wordBreak: "break-all",
            }}
            dangerouslySetInnerHTML={{
              __html: item.countryGeneric ?? item.countrySpecific ?? "--",
            }}
          />
        </Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          sx={{
            maxWidth: "75%",
            gap: 0.5,
          }}
        >
          <BusinessIcon
            sx={{
              height: "16px !important",
              width: "16px !important",
            }}
          />
          <Typography
            variant={"subtitle2"}
            fontWeight={300}
            color={"inherit"}
            sx={{
              mb: "-2px",
              width: "max-content",
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
              wordBreak: "break-all",
            }}
            dangerouslySetInnerHTML={{
              __html: item.agency ?? "--",
            }}
          />
        </Stack>
        <Stack direction={"row"} alignItems={"center"} gap={0.5}>
          <CreateNewFolderOutlinedIcon
            fontSize={"small"}
            sx={{
              fill: theme.palette.success.main,
              width: "16px !important",
              height: "16px !important",
            }}
          />
          <Typography
            variant={"subtitle2"}
            fontWeight={300}
            sx={{
              mb: "-2px",
              // textDecoration: "underline",
              color: theme.palette.success.main,
              // display: "-webkit-box",
              // overflow: "hidden",
              // WebkitBoxOrient: "vertical",
              // WebkitLineClamp: 1,
              display: "flex",
              flexWrap: "no-wrap",
              gap: 0.5,
            }}
            component={"div"}
          >
            {item.userPipelines.length > 0 ? (
              item.userPipelines.length === 1 ? (
                <Typography
                  variant={"subtitle2"}
                  fontWeight={300}
                  color={"inherit"}
                  sx={{
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    textDecoration: "underline",
                    wordBreak: "break-all",
                  }}
                >
                  {item.userPipelines[0].name}
                </Typography>
              ) : (
                <>
                  <Typography
                    variant={"subtitle2"}
                    fontWeight={300}
                    color={"inherit"}
                    sx={{
                      display: "-webkit-box",
                      overflow: "hidden",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 1,
                      textDecoration: "underline",
                      wordBreak: "break-all",
                    }}
                  >
                    {item.userPipelines[0].name}
                  </Typography>
                  <Typography
                    variant={"subtitle2"}
                    color={"inherit"}
                    fontWeight={300}
                    sx={{ textDecoration: "none" }}
                  >
                    and
                  </Typography>
                  <Typography
                    variant={"subtitle2"}
                    color={"inherit"}
                    fontWeight={300}
                    sx={{
                      textDecoration: "underline",
                      minWidth: "fit-content",
                    }}
                  >
                    {item.userPipelines.length - 1} more pipeline
                    {item.userPipelines.length === 2 ? "" : "s"}
                  </Typography>
                </>
              )
            ) : (
              "--"
            )}
          </Typography>
        </Stack>
      </Stack>
      <Typography
        variant={"h6"}
        color={"inherit"}
        fontWeight={"500"}
        fontSize={"14px"}
        dangerouslySetInnerHTML={{
          __html: item.title ?? "",
        }}
        {...(item.sourceId &&
          item.id && {
            component: "a",
            href:
              item.datasetType === "Forecast"
                ? `/forecast/${item.sourceId}`
                : item.datasetType === "Contract"
                ? `/contracts/${item.sourceId}`
                : `/grants/${item.sourceId}`,
            target: "_blank",
            color: theme.palette.primary.main,
            sx: {
              textDecoration: "underline",
              cursor: "pointer",
              width: "fit-content",
            },
          })}
      />
      <Typography
        variant={"subtitle2"}
        color={"textSecondary"}
        fontWeight={"300"}
        fontSize={"13px"}
        ref={descriptionRef}
        dangerouslySetInnerHTML={{
          __html: item.description ?? "",
        }}
        sx={{
          "p:first-of-type": {
            marginTop: `0 !important`,
          },
          "& > *": {
            color: `${theme.palette.text.secondary} !important`,
            "& > *": {
              color: `${theme.palette.text.secondary} !important`,
            },
          },
          lineHeight: "24px",
          display: "-webkit-box",
          overflow: "hidden",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: isTruncated ? 2 : Number.MAX_SAFE_INTEGER,
        }}
      />
      {/*<Typography variant={"body2"} color={"inherit"}>*/}
      {/*  Changed fields:{" "}*/}
      {/*  {item.changedColumn.split(",").join(", ") ?? "None"}*/}
      {/*</Typography>*/}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          "& > button": {
            p: 0.5,
          },
        }}
      >
        {shouldShowReadMore() ? (
          <Button
            size={"small"}
            sx={{
              color: theme.palette.text.primary,
              textDecoration: "underline",
            }}
            onClick={handleReadMore}
          >
            Read {isTruncated ? "more" : "less"}
          </Button>
        ) : (
          <div />
        )}
        {item.hasAttachments ? (
          <Button
            size={"small"}
            startIcon={
              <AttachFile
                sx={{
                  height: "16px !important",
                  width: "16px !important",
                }}
              />
            }
            sx={{
              ...(!handleOpenModal && { display: "none" }),
              color: theme.palette.text.primary,
              textDecoration: "underline",
            }}
            onClick={() => {
              handleOpenModal?.(item);
            }}
          >
            Attachments
          </Button>
        ) : (
          <div />
        )}
      </Box>
    </Box>
  );
};

export default DetailsRow;
